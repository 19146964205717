<template>
  <div class="brand-rank">
    <navigationTop
      startTitle="平台"
      endTitle=""
      :visibleOption="['categoryCascader', 'brandSettings', 'topBrandRadio']"
      :defaultCategoryValue="[['zhinan'], ['zujian']]"
      brandType="brand"
      @onNaviEvent="onNaviEvent"
    />
    <charts v-model="chartData">
      <template v-slot:header>
        <echartCondition
          :resetTimes="resetTimes"
          @setCheck="setCheck"
          @setTop="setChart"
        ></echartCondition>
      </template>
      <template v-slot:summary>
        <dataSummary
          header="品牌销售金额"
          :toolTip="brandAmountTooltip"
          :text="toThousands(chartData.sales_amount_total)"
          type="amount"
        />
        <dataSummary
          header="品牌销售件数"
          :toolTip="brandNumTooltip"
          :text="toThousands(chartData.sales_num_total)"
          type="number"
          unit="件"
        />
      </template>
    </charts>

    <chartTable :tableData="tableData" :firstColumns="firstColumns" @goShop="goBrand" typeName="品牌">
      <template v-slot:search>
        <tableCondition
          :resetTimes="resetTimes"
          @setData="setTableData"
        ></tableCondition>
      </template>
      <template v-slot:screen>
        <openTable :tableData="tableData" @goShop="goBrand" :firstColumns="firstColumns" :resetTimes="resetTimes">
        </openTable>
      </template>
    </chartTable>
  </div>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue'

import charts from '@/components/charts/charts'
import axios from '@/utils/request'
import chartTable from '@/components/chart-table/index'
import navigationTop from '@/components/navigation-top'
import echartCondition from '@/components/charts/echart-condition'
import { chartsConditionFn } from '@/components/charts/charts-condition-fn'
import tableCondition from '@/components/chart-table/table-condition'
import dataSummary from '@/components/data-summary'
import { toThousands } from '@/utils/index'
import isInterface from '@/components/Interface-condition'
import debounce from 'lodash/debounce'
import { brandAmountTooltip, brandNumTooltip } from '@/components/global-tooltip'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import openTable from '@/components/chart-table/open-table.vue'

const FIRST_COLUMNS = [
  {
    prop: 'amount',
    label: '销售金额'
  },
  {
    label: '销售件数',
    prop: 'num'
  },
  {
    label: '成交价',
    prop: 'avg',
    tooltip: '通过商品页面原价，叠加全部促销活动后，得到的最低到手价'
  }
]
export default defineComponent({
  name: 'ShopRank',
  components: {
    charts,
    chartTable,
    navigationTop,
    echartCondition,
    tableCondition,
    dataSummary,
    openTable
  },
  setup () {
    const chartData = reactive({})
    let setChartCondition = {}
    let setTableCondition = {}
    const chartCondition = reactive({ amount: true, number: true })
    let constData = {}
    const tableObj = reactive({})

    const getChartData = debounce(() => {
      if (!isInterface(setChartCondition)) return
      axios
        .get('/api/v1/brand_data/statistics', { params: setChartCondition, loading: true })
        .then((res) => {
          if (res && res.error_code === 0) {
            constData = JSON.parse(JSON.stringify(res.data))
            Object.keys(res.data).forEach((d) => {
              chartData[d] = res.data[d]
            })
            chartsConditionFn(chartCondition, constData, chartData)
          }
        })
    }, 300)

    const tableData = reactive({})
    const getTableData = debounce(() => {
      if (!isInterface(setChartCondition)) return
      axios
        .get('/api/v1/brand_data/data_list', { params: setTableCondition, loading: true })
        .then((res) => {
          if (res && res.error_code === 0) {
            Object.keys(res.data).forEach(key => {
              tableData[key] = res.data[key]
            })
          }
        })
    }, 300)

    watch(tableObj, () => {
      setTableCondition = { ...tableObj }
    })

    // 重置列表查看日期区间
    const resetTimes = reactive({})
    const onNaviEvent = (value) => {
      setChartCondition = { ...setChartCondition, ...value }
      setTableCondition = { ...setTableCondition, ...value }
      resetTimes.dateValue = value
      getChartData()
      getTableData()
    }

    const setChart = (value) => {
      setChartCondition = { ...setChartCondition, ...value }
      getChartData()
    }

    const setCheck = (value) => {
      Object.keys(value).forEach((l) => {
        chartCondition[l] = value[l]
      })
      chartsConditionFn(value, constData, chartData)
    }

    const setTableData = (value) => {
      setTableCondition = { ...setTableCondition, ...value }

      getTableData()
    }

    const store = useStore()
    const phone = store.state.phone
    const router = useRouter()
    const goBrand = (row) => {
      localStorage.setItem(
        `${phone}_brand_dropdown`,
        localStorage.getItem(`${phone}_dropdown`)
      )
      localStorage.setItem(`${phone}_brand_setting`, row.id)
      localStorage.setItem(
        `${phone}_brand_firstName`,
        localStorage.getItem(`${phone}_dropdown_name`)
      )
      localStorage.setItem(`${phone}_brand_secondName`, row.category_name)
      const { href } = router.resolve({ path: '/brand/detail' })
      window.open(href, '_blank')
    }

    return {
      firstColumns: FIRST_COLUMNS,
      chartCondition,
      chartData,
      tableData,
      tableObj,
      resetTimes,
      setTableData,
      onNaviEvent,
      setCheck,
      toThousands,
      setChart,
      brandAmountTooltip,
      brandNumTooltip,
      goBrand
    }
  }
})
</script>
<style lang="scss" scoped>
  .brand-rank {
    .charts {
      margin-top: 164px;
    }
  }
</style>
